import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  position: relative;
  @media ${({ theme }) => theme.device.mobile} {
    gap: 30px;
  }

  & > #intro {
    padding: 0px 20px;
    text-align: center;
    & > h2 {
      background: linear-gradient(107deg, #2beede 30%, #583eee 70%);
      background-clip: text;
      color: transparent;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      ${({ theme }) => theme.wev_font.Bold_36}
    }
    > p {
      margin-top: 20px;
      ${({ theme }) => theme.wev_font.Regular_20};
    }
  }
`

export const Card = styled.div`
  position: absolute;
  border-radius: 2rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 2rem;
  width: 100%;
  max-width: 38rem;
  background: #fff;
  bottom: 0;

  & > h3 {
    ${({ theme }) => theme.wev_font.Bold_28}
    margin-bottom: 20px;
  }

  & em {
    font-style: normal;
    color: ${({ theme }) => theme.wev_color.primary};
  }

  & > ul > li {
    margin-bottom: 5px;
    ${({ theme }) => theme.wev_font.Regular_16};
    list-style-type: disc;
    list-style-position: inside;
    text-indent: -1.2em;
    padding-left: 1.2em;
  }

  @media ${({ theme }) => theme.device.mobile} {
    border: 1px solid ${({ theme }) => theme.wev_color.outline};
    position: unset;
    box-shadow: unset;
    max-width: unset;
    border-radius: 20px;
  }
`

export const ImageOverlay = styled.div<{ isEven?: boolean; idx: number }>`
  position: relative;
  padding-bottom: 6rem;
  position: relative;
  display: flex;

  ${({ isEven }) => (isEven ? '' : 'justify-content: end;')};

  & > #decorationContainer {
    /* animation: ${({ idx }) => `item${idx + 1}move ${1.5 * (idx + 1)}s infinite linear`}; */
    position: absolute;
    bottom: 6rem;
    ${({ isEven }) => (isEven ? 'right' : 'left')}: -8rem;
    @keyframes item1move {
      0%,
      100% {
        transform: translate(-40%, -50%);
      }
      50% {
        transform: translate(-40%, -60%);
      }
    }
    @keyframes item2move {
      0% {
        transform: translate(0, 0);
      }
      25% {
        transform: translate(-20%, 20%);
      }
      50% {
        transform: translate(40%, 70%);
      }
      75% {
        transform: translate(20%, 20%);
      }
      100% {
        transform: translate(0, 0);
      }
    }
    @keyframes item3move {
      0% {
        transform: translate(0, 0) rotate(0deg);
      }
      25% {
        transform: translate(-20%, 20%) rotate(45deg);
      }
      50% {
        transform: translate(40%, 70%) rotate(90deg);
      }
      75% {
        transform: translate(20%, 20%) rotate(135deg);
      }
      100% {
        transform: translate(0, 0) rotate(180deg);
      }
    }
  }

  ${Card} {
    ${({ isEven }) => (isEven ? 'right' : 'left')}: 0
  }

  @media ${({ theme }) => theme.device.mobile} {
    padding-bottom: unset;
    display: unset;
    justify-content: unset;
    top: -10rem;

    & > #decorationContainer {
      display: none;
    }
  }
`
export const CardImage = styled.div`
  width: 100%;
`

export const MobileImageOverlay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`
