import MembershipProcess from './MembershipProcess'
import { useTranslation } from 'next-i18next'
import parse from 'html-react-parser'
import { newlineParse } from '@src/utils/mobileParse'
import { useResponsive } from '@src/hooks/useResponsive'
import { Arrow_Right_Alt } from '@src/assets/icons'
import { useRouter } from 'next/router'
import * as S from './Membership.styled'

const Membership = () => {
  const router = useRouter()
  const { t } = useTranslation('main')
  const { isMobile } = useResponsive(768)
  return (
    <S.Content>
      <div id="intro" data-aos="fade-up">
        <h2>{parse(t('wevApp.membership.title'))}</h2>
        <p>{newlineParse(isMobile, t('wevApp.membership.subtitle'))}</p>
      </div>

      <MembershipProcess />
      <S.MembershipButton onClick={() => router.push('/user/membership')}>
        {t('wevApp.membership.cta')}
        <Arrow_Right_Alt fill="#ffffff" width={24} height={24} />
      </S.MembershipButton>
    </S.Content>
  )
}

export default Membership
