import React, { useEffect, useRef, useState } from 'react'
import IntroVideo from './introVideo/IntroVideo'
import Service from './service/Service'
import WevApp from './wevApp/WevApp'
import Membership from './membership/Membership'
import Charger from './charger/Charger'
import News from './news/News'
import { useAppDispatch } from '@src/hooks/redux'
import { setScrollSidebarIconColor } from '@src/store/modules/commons'
import * as S from './Main.styled'

const Main = () => {
  const dispatch = useAppDispatch()
  const [outVideo, setOutVideo] = useState(false)

  const ref = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return

      const { top } = ref.current.getBoundingClientRect()

      if (top < window.innerHeight && top > 0) {
        // 스크롤이 ref 아래에 있을 때
        setOutVideo(true)
        dispatch(setScrollSidebarIconColor('#fff'))
      } else {
        // 스크롤이 ref 위에 있을 때
        setOutVideo(false)
        dispatch(setScrollSidebarIconColor('rgb(0, 0, 0)'))
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [dispatch])

  return (
    <S.MainWrapper>
      <S.VideoSection>
        <IntroVideo outVideo={outVideo} />
      </S.VideoSection>

      <S.ContentSection>
        <div ref={ref}></div>
        <Service />
        <WevApp />
        <Membership />
        <Charger />
        <News />
      </S.ContentSection>
    </S.MainWrapper>
  )
}

export default Main
