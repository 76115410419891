import styled from 'styled-components'

export const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
`
export const VideoSection = styled.div`
  width: 100%;
  height: 100vh;
`
export const ContentSection = styled.div`
  width: 100%;
`
