import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > a {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-top: 30px;
    align-self: end;
    > span {
      ${({ theme }) => theme.wev_font.Regular_18}
    }
  }

  & > h2 {
    padding: 0px 20px;
    text-align: center;
    background: linear-gradient(150deg, #2beede 10%, #583eee 90%);
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    ${({ theme }) => theme.wev_font.Bold_36}
  }
`

export const ListWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-top: 20px;
  @media ${({ theme }) => theme.device.pc} {
    grid-template-columns: repeat(2, 1fr);
  }
  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 20px;
    display: block;
  }
`
export const Item = styled.div`
  padding: 30px;
  border-radius: 30px;
  border: 1px solid ${({ theme }) => theme.wev_color.outline};
  height: fit-content;
  @media ${({ theme }) => theme.device.pc} {
    padding: 20px;
  }
  #image {
    width: 100%;
    aspect-ratio: 1/0.58;
    overflow: hidden;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  #content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    margin-top: 20px;
    #title {
      display: flex;
      align-items: center;
      gap: 10px;
      min-height: 50px;
      max-height: 50px;
      > svg {
        min-width: 20px;
      }
      > p {
        ${({ theme }) => theme.clamp.line2};
        ${({ theme }) => theme.wev_font.sBold_18}
      }
    }
    > p {
      min-height: 64px;
      max-height: 64px;
      ${({ theme }) => theme.wev_font.Regular_14}
      ${({ theme }) => theme.clamp.line4};
      color: ${({ theme }) => theme.wev_color.hint};
    }
  }
  #date {
    margin-top: 20px;
    ${({ theme }) => theme.wev_font.Regular_16}
    color: ${({ theme }) => theme.wev_color.hint};
  }
  :hover {
    border: 1px solid ${({ theme }) => theme.wev_color.primary};
    #content {
      #title {
        color: ${({ theme }) => theme.wev_color.primary};
      }
      > p {
        color: ${({ theme }) => theme.wev_color.text};
      }
    }
    #date {
      color: ${({ theme }) => theme.wev_color.primary};
    }
  }
`

export const MobileListItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 2.2fr;
  gap: 20px;
  width: 100%;
  padding: 20px 30px;
  border-bottom: 1px solid ${({ theme }) => theme.wev_color.outline};
  #image {
    width: 100%;
    aspect-ratio: 1/0.58;
    overflow: hidden;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  #content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    #title {
      display: flex;
      align-items: center;
      gap: 10px;
      > p {
        ${({ theme }) => theme.clamp.line2};
        ${({ theme }) => theme.wev_font.sBold_20}
      }
      > svg {
        min-width: 20px;
      }
    }
    #date {
      width: 100%;
      text-align: right;
      font-size: 14px;
      color: ${({ theme }) => theme.wev_color.hint};
    }
  }
  :first-child {
    border-top: 1px solid ${({ theme }) => theme.wev_color.outline};
  }
`

export const DefaultImage = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.wev_color.outline};
`
