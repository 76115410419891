import { Arrow_Right_Alt, Check_Circle_F } from '@src/assets/icons'
import Image from 'next/legacy/image'
import Link from 'next/link'
import { ChargerColors, SelectedCharger } from './types'
import { useTranslation } from 'next-i18next'
import { useResponsive } from '@src/hooks/useResponsive'
import * as S from './Charger.styled'
interface IProps {
  selectedCharger: SelectedCharger
  selectedColor: ChargerColors | null
  onClick: (colorName: ChargerColors) => void
}

const ChargerInfo = (props: IProps) => {
  const { selectedCharger, selectedColor, onClick } = props
  const { t } = useTranslation('main')
  const { isMobile } = useResponsive(768)

  return (
    <S.ChargerContainer>
      <div id="media">
        <div style={{ width: 400, height: 400, position: 'relative' }}>
          <Image
            src={`${selectedCharger.imageSrc}_${selectedColor}.png`}
            alt={selectedCharger.modelName}
            layout="fill"
            objectFit="contain"
            priority
          />
        </div>

        <div id="controller">
          {selectedCharger.colors.map(({ hexValue, nameKr, colorName }) => (
            <S.ChargerColorButton
              isSelected={selectedColor === colorName}
              onClick={() => onClick(colorName as ChargerColors)}
              selectedColor={hexValue}
              key={`${selectedCharger.modelName}${colorName}`}
            >
              <div className="colorSelectorWrapper">
                {selectedColor === colorName ? <Check_Circle_F fill="#583EEE" /> : null}
                <div className="colorSelector" />
              </div>
              <p>{t(`wevApp.charger.colors.${nameKr}`)}</p>
            </S.ChargerColorButton>
          ))}
        </div>
      </div>

      <div id="description">
        <ul>
          {selectedCharger.descriptions.map((description, idx) => (
            <li key={`${selectedCharger.modelName}Description${idx}`}>{description}</li>
          ))}
        </ul>
        <Link id="detailsLink" className="space-x-2" href={selectedCharger.detailsLink as string}>
          <span>{t('wevApp.charger.detailsLink')}</span>
          <Arrow_Right_Alt width={isMobile ? 18 : 24} />
        </Link>
      </div>
    </S.ChargerContainer>
  )
}

export default ChargerInfo
