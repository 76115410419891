import { css } from 'styled-components'

export const defaultColor = css`
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.wev_color.outline};
  color: ${({ theme }) => theme.wev_color.text};
`
export const grayFilledColor = css`
  background-color: ${({ theme }) => theme.wev_color.outline};
  border: 1px solid ${({ theme }) => theme.wev_color.outline};
  color: ${({ theme }) => theme.wev_color.hint};
`
export const grayLineColor = css`
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.wev_color.outline};
  color: ${({ theme }) => theme.wev_color.hint};
`
export const primaryLineColor = css`
  background-color: ${({ theme }) => theme.wev_color.sub_btn_hover};
  border: 1px solid ${({ theme }) => theme.wev_color.primary};
  color: ${({ theme }) => theme.wev_color.primary};
`
export const primaryFillColor = css`
  background-color: ${({ theme }) => theme.wev_color.primary};
  border: 1px solid ${({ theme }) => theme.wev_color.primary};
  color: ${({ theme }) => theme.wev_color.white};
`
export const errorColor = css`
  background-color: ${({ theme }) => theme.wev_color.delete_hover};
  color: ${({ theme }) => theme.wev_color.delete};
  border: 1px solid ${({ theme }) => theme.wev_color.delete};
`
export const warningColor = css`
  background-color: #fff2e3;
  color: #f09020;
  border: 1px solid #f09020;
`
