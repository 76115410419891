import { Container, Unstable_Grid2 as Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import ChargerInfo from './ChargerInfo'
import { CHARGER_MODELS_EN, CHARGER_MODELS_JP, CHARGER_MODELS_KR } from './constants'
import { ChargerColors, ChargerModels } from './types'
import { useRouter } from 'next/router'
import { useResponsive } from '@src/hooks/useResponsive'
import { nanoid } from '@reduxjs/toolkit'
import * as S from './Charger.styled'

const Charger = () => {
  const router = useRouter()
  const [selectedCharger, setSelectedCharger] = useState<any>()
  const [selectedColor, setSelectedColor] = useState<ChargerColors | null>(null)
  const { isMobile } = useResponsive(768)

  const handleSelectCharger = (selectedModelName: ChargerModels) => {
    const [charger] = getChargerData(locale).chargerInfo.filter(({ modelName }) => modelName === selectedModelName)
    setSelectedCharger(charger)
  }

  const handleClickColor = (colorName: ChargerColors) => {
    setSelectedColor(colorName)
  }

  useEffect(() => {
    if (selectedCharger) {
      const [firstAvailableColor] = selectedCharger.colors
      setSelectedColor(firstAvailableColor.colorName as ChargerColors)
    }
  }, [selectedCharger])

  const locale = router.locale || 'ko'

  const getChargerData = (language: string) => {
    switch (language) {
      case 'en':
        return {
          chargerInfo: CHARGER_MODELS_EN,
        }
      case 'jp':
        return {
          chargerInfo: CHARGER_MODELS_JP,
        }
      default: // 한국어(kr)를 기본으로 설정
        return {
          chargerInfo: CHARGER_MODELS_KR,
        }
    }
  }
  useEffect(() => {
    setSelectedCharger(getChargerData(locale).chargerInfo[0])
  }, [locale])
  return (
    <Container maxWidth="lg" sx={{ mb: isMobile ? '70px' : '120px' }} data-aos="fade-up">
      {selectedCharger ? (
        <S.Content>
          <h2>{selectedCharger.title}</h2>

          <Grid container columnSpacing={isMobile ? 1 : 2} rowSpacing={isMobile ? 1 : 2}>
            {getChargerData(locale).chargerInfo.map(({ modelName, capacities }, idx) => (
              <Grid xs={6} md={3} display="flex" justifyContent="center" alignItems="center" key={nanoid()}>
                <S.SelectChargerButton
                  className="selectChargerButton"
                  onClick={() => handleSelectCharger(modelName as ChargerModels)}
                  isSelected={modelName === selectedCharger.modelName}
                >
                  <span>
                    {idx === 0 && modelName.split(' ')[0]}{' '}
                    {capacities.map((capacity, idx) => (
                      <span key={`modelName${capacity}`}>
                        {idx && idx === capacities.length - 1 ? '/' : null}
                        {capacity.toString()}
                      </span>
                    ))}
                    kW
                  </span>
                  {/* <span>
                    (
                    {capacities.map((capacity, idx) => (
                      <span key={`modelName${capacity}`}>
                        {idx && idx === capacities.length - 1 ? '/' : null}
                        {capacity.toString()}
                      </span>
                    ))}
                    kW)
                  </span> */}
                </S.SelectChargerButton>
              </Grid>
            ))}
          </Grid>

          <ChargerInfo selectedCharger={selectedCharger} selectedColor={selectedColor} onClick={handleClickColor} />
        </S.Content>
      ) : null}
    </Container>
  )
}

export default Charger
