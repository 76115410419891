import { Container } from '@mui/material'
import ImageOverlay from './ImageOverlay'
import { SERVICE_INFO_EN, SERVICE_INFO_JP, SERVICE_INFO_KR } from './constants'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from 'react'
import { useResponsive } from '@src/hooks/useResponsive'
import { nanoid } from '@reduxjs/toolkit'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import parse from 'html-react-parser'
import * as S from './Service.styled'
import { newlineParse } from '@src/utils/mobileParse'

const Service = () => {
  const router = useRouter()
  const { isMobile } = useResponsive(768)
  const { t } = useTranslation('main')
  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 3,
      offset: 200,
      once: true,
    })
  }, [])

  const locale = router.locale || 'ko'

  const getChargerData = (language: string) => {
    switch (language) {
      case 'en':
        return {
          serviceInfo: SERVICE_INFO_EN,
        }
      case 'jp':
        return {
          serviceInfo: SERVICE_INFO_JP,
        }
      default: // 한국어(kr)를 기본으로 설정
        return {
          serviceInfo: SERVICE_INFO_KR,
        }
    }
  }

  return (
    <>
      <Container
        maxWidth="lg"
        sx={{
          mt: isMobile ? '4rem' : '8rem',
          overflowX: {
            xl: 'unset',
          },
        }}
      >
        <S.Content>
          <div id="intro" data-aos="fade-up">
            <h2>{parse(t('service.title'))}</h2>
            <p>{newlineParse(isMobile, t('service.subtitle'))}</p>
          </div>
          {isMobile ? (
            <div className="w-full flex flex-col min-h-[300px] gap-[50px] ">
              {getChargerData(locale).serviceInfo.map(({ image, info }, idx) => (
                <S.MobileImageOverlay data-aos="fade-up" key={nanoid()}>
                  <S.CardImage>
                    <img src={image.src} alt={image.alt} />
                  </S.CardImage>
                  <S.Card>
                    <h3>{info.title}</h3>
                    <ul>
                      {info.descriptions.map((description) => (
                        <li key={description}>{description}</li>
                      ))}
                    </ul>
                  </S.Card>
                </S.MobileImageOverlay>
              ))}
            </div>
          ) : (
            <>
              {getChargerData(locale).serviceInfo.map(({ image, info }, idx) => (
                <ImageOverlay key={image.alt} image={image} info={info} idx={idx} />
              ))}
            </>
          )}
        </S.Content>
      </Container>
    </>
  )
}

export default Service
