import { primaryFillColor } from '@src/styles/componentColors'
import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 80px 0 70px 0;
  @media ${({ theme }) => theme.device.mobile} {
    margin: 70px 0;
  }
  & > * {
    margin-bottom: 30px;
    @media ${({ theme }) => theme.device.mobile} {
      margin-bottom: 30px;
    }
  }

  & h2,
  & p {
    text-align: center;
  }

  & > #intro {
    padding: 0px 20px;
    & > h2 {
      background: linear-gradient(150deg, #2beede 17.41%, #583eee 108.27%);
      color: transparent;
      background-clip: text;
      -webkit-background-clip: text;
      ${({ theme }) => theme.wev_font.Bold_36}
    }

    > p {
      margin-top: 30px;
      margin-bottom: 20px;
      ${({ theme }) => theme.wev_font.Regular_20};
    }
  }
  & > #membership {
    background-color: ${({ theme }) => theme.wev_color.primary};
    padding: 0.5rem 1rem;
    color: #fff;
    border-radius: 6rem;
  }
`

export const MembershipButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 100px;
  gap: 10px;
  ${({ theme }) => theme.wev_font.Regular_16};
  ${primaryFillColor}
`
