import { useResponsive } from '@src/hooks/useResponsive'
import Image from 'next/legacy/image'
import * as S from './Service.styled'
interface IProps {
  image: {
    src: string
    alt: string
    decoration: {
      src: string
      size: number
    }
  }
  info: {
    title: JSX.Element
    descriptions: string[]
  }
  idx: number
}

const ImageOverlay = ({ image, info, idx }: IProps) => {
  const { isMobile } = useResponsive(768)

  return (
    <>
      {isMobile ? (
        <S.MobileImageOverlay>
          <Image
            src={image.src}
            alt={image.alt}
            width={100}
            height={100}
            sizes={undefined}
            layout={'fill'}
            objectFit="contain"
          />
          <S.Card>
            <h3>{info.title}</h3>
            <ul>
              {info.descriptions.map((description) => (
                <li key={description}>{description}</li>
              ))}
            </ul>
          </S.Card>
        </S.MobileImageOverlay>
      ) : (
        <S.ImageOverlay isEven={idx % 2 === 0} idx={idx} data-aos="fade-up">
          {/* <S.ImageOverlay isEven={idx % 2 === 0}> */}
          <div id="decorationContainer">
            <Image
              src={image.decoration.src}
              alt={`${image.alt} Decoration`}
              width={image.decoration.size}
              height={image.decoration.size}
            />
          </div>

          <div
            style={{
              width: 638,
              height: 414,
              position: 'relative',
              // border: '1px solid orange',
            }}
          >
            <Image
              src={image.src}
              alt={image.alt}
              width={undefined}
              height={undefined}
              sizes={undefined}
              layout={'fill'}
              objectFit="contain"
            />
          </div>

          <S.Card>
            <h3>{info.title}</h3>
            <ul>
              {info.descriptions.map((description) => (
                <li key={description}>{description}</li>
              ))}
            </ul>
          </S.Card>
        </S.ImageOverlay>
      )}
    </>
  )
}

export default ImageOverlay
