import styled from 'styled-components'

export const Content = styled.div<{ isTextHide: boolean }>`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  text-align: center;

  & > h1 {
    opacity: ${({ isTextHide }) => (isTextHide ? 0 : 1)};
    ${({ theme }) => theme.wev_font.Bold_72}
  }
  & > p {
    opacity: ${({ isTextHide }) => (isTextHide ? 0 : 1)};
    ${({ theme }) => theme.wev_font.Regular_52}
  }

  & > h1,
  & > p {
    z-index: 2;
  }
`

export const VideoWrapper = styled.div<{ isTextHide: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &:after {
    content: '';
    background: ${({ isTextHide }) => (isTextHide ? 'none' : 'rgba(0, 0, 0, 0.5);')};
    height: 100vh;
    width: 100vw;
    z-index: 1;
    top: 0;
    right: 0;
    position: absolute;
    z-index: 0;
  }
`

export const VideoControllerBox = styled.div`
  z-index: 2;
  width: 15rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  left: 8rem;
  bottom: 8rem;
  top: unset;
  right: unset;
  transform: unset;

  @media ${({ theme }) => theme.device.pc} {
    top: 70%;
    right: 50%;
    left: unset;
    bottom: unset;
    transform: translate(50%, 140%);
  }
`
