import instance from '@src/api/axios'
import { IFAQListParams, INewsListParams, INoticeListParams } from '../types'

export const supportApis = {
  getNoticeSubCategory: () => instance.get(`/management/notice/sub-category`),
  getNoticeList: (data: INoticeListParams) =>
    instance.get(
      `/guest/board?category=notice&qCategory=all&p=${data.page}` +
        `${data.category ? `&filterSub=${data.category}` : ''}` +
        `${data.query ? `&q=${data.query}` : ''}`,
    ),
  getDetail: (data: string) => instance.get(`/guest/board/${data}`),
  getDetailExtra: (data: any) =>
    instance.get(`/guest/board/${data.id}/extra` + `${data.category ? `?filterSub=${data.category}` : ''}`),
  getEventList: (data: number) => instance.get(`/guest/board/event?listNum=12&p=${data}&imageType=3`),
  getFaqSubCategory: () => instance.get(`/management/faq/category`),
  getFaqList: (data: IFAQListParams) =>
    instance.get(
      `/management/faq/list?p=${data.page}&category=all` +
        `${data.category ? `&faq_category_id=${data.category}` : ''}` +
        `${data.query ? `&query=${data.query}` : ''}`,
    ),
  getFaqDetail: (data: string) => instance.get(`/management/faq/select-list?id=${data}`),
  getNewsList: (data: INewsListParams) =>
    instance.get(
      `/guest/board?category=news&qCategory=all&listNum=12&p=${data.page}` + `${data.query ? `&q=${data.query}` : ''}`,
    ),
}
