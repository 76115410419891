export const WEV_APP_SCREENS = [
  {
    src: '/images/main/renewal/wevApp/wev_app_start_screen.png',
    title: '스플래쉬',
  },
  {
    src: '/images/main/renewal/wevApp/wev_app_auth_screen.png',
    title: '로그인',
  },
  {
    src: '/images/main/renewal/wevApp/wev_app_home_screen.png',
    title: '위브이 지갑',
  },
  {
    src: '/images/main/renewal/wevApp/wev_app_usage_history_screen.png',
    title: '간편 충전 내역',
  },
  {
    src: '/images/main/renewal/wevApp/wev_app_charging_history_screen.png',
    title: '충전/결제 내역',
  },
]
