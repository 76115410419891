import { SelectedCharger } from './types'

export const WHITE_CHARGER = {
  colorName: 'white',
  hexValue: '#fff',
  nameKr: '화이트',
}
export const BLACK_CHARGER = {
  colorName: 'black',
  hexValue: '#000',
  nameKr: '블랙',
}
export const BLUE_CHARGER = {
  colorName: 'blue',
  hexValue: '#00B2FF',
  nameKr: '블루',
}
export const ORANGE_CHARGER = {
  colorName: 'orange',
  hexValue: '#FF6B17',
  nameKr: '오렌지',
}
export const YELLOW_CHARGER = {
  colorName: 'yellow',
  hexValue: '#FFDD2C',
  nameKr: '옐로우',
}
export const MINT_CHARGER = {
  colorName: 'mint',
  hexValue: '#2BEEDE',
  nameKr: '민트',
}
export const PURPLE_CHARGER = {
  colorName: 'purple',
  hexValue: '#917DFF',
  nameKr: '퍼플',
}

export const CHARGER_MODELS_KR = [
  {
    title: (
      <>
        컴팩트한 디자인과 다양한 기능을 갖춘
        <br />
        개인용 전기차 충전기
      </>
    ),
    modelName: '비공용 완속 충전기',
    capacities: [7],
    descriptions: [
      <>
        개인용으로 사용 가능한 <em>홈충전기</em>
      </>,
      <>
        사용자 맞춤 <em>예약 충전 가능</em>
      </>,
      <>충전 커넥터 거치대를 본체에 구성하여 편리하게 사용 가능</>,
      <>
        <em>5가지 색상 및 디자인 특허를 보유</em>한 컴팩트한 디자인
      </>,
      <>
        <em>상태 표시 LED</em>로 차량의 충전 상태 확인 가능
      </>,
      <>
        <em>RFID 방식</em>을 통해 사용자 인증 가능
      </>,
      <>
        <em>벽부형, 스탠드형</em>으로 <em>다양한 환경</em>에 맞춰 사용 가능
      </>,
      <>
        <em>충전속도 선택 조절 가능 (5kW/7kW)</em>
      </>,
    ],
    detailsLink: '/wev/product/non_public',
    imageSrc: '/images/main/renewal/chargers/privateSlowSpeedCharger/private_slow_speed_charger',
    colors: [WHITE_CHARGER, BLACK_CHARGER, BLUE_CHARGER, ORANGE_CHARGER, YELLOW_CHARGER, MINT_CHARGER],
  },
  {
    title: (
      <>
        기본에 충실한 성능,
        <br />
        안정적인 충전과 다양한 라인업
      </>
    ),
    modelName: '완속충전기',
    capacities: [7, 11],
    descriptions: [
      <>
        <em>화재 예방형 충전기</em>(PLC모뎀 장착) - 옵션사항
      </>,
      <>
        <em>5가지 색상 및 디자인 특허를 보유</em>한 컴팩트한 디자인
      </>,
      <>
        <em>7인치 터치형 디스플레</em>이로 최적의 UI 제공
      </>,
      <>
        관제 시스템 및 관리자 페이지에서 <em>충전기 온도 실시간 확인</em> 가능
      </>,
      <>간편한 개폐 및 수리용이성 향상</>,
      <>
        자체 개발 충전커넥터&케이블 내장 <em>자동 소화약제로 화재 대응</em> 가능
      </>,
      <>
        <em>벽부형, 스탠드형</em>으로 <em>다양한 환경</em>에 맞춰 사용 가능
      </>,
    ],
    detailsLink: '/wev/product/public_7kW',
    imageSrc: 'images/main/renewal/chargers/publicSlowSpeedCharger/public_slow_speed_charger',
    colors: [WHITE_CHARGER, BLACK_CHARGER, BLUE_CHARGER, ORANGE_CHARGER, YELLOW_CHARGER, MINT_CHARGER],
  },
  {
    title: (
      <>
        WEV 시그니처 모델
        <br />
        빠른충전, 다양한 타입, 다양한 공간
      </>
    ),
    modelName: '중속충전기',
    capacities: [30],
    descriptions: [
      <>
        PLC 모뎀 장착 및 <em>SOC제어로 전기차 배터리 과충전 방지</em>
      </>,
      <>자체 개발 충전커넥터&케이블 내장 자동 소화약제로 화재 대응 가능</>,
      <>
        <em>21.5인치 대형 디스플레이</em>로 사용자 시인성 및 편리성 향상
      </>,
      <>고효율 기자재 인증 완료</>,
      <>
        네트워크 원격 제어로 <em>실시간 업데이트 및 오류감지 모니터링</em> 가능
      </>,
      <>
        파워모듈 측면 배치로 간편한 <em>개폐 및 수리용이성 향상</em>
      </>,
    ],
    detailsLink: '/wev/product/30kW',
    imageSrc: '/images/main/renewal/chargers/mediumSpeedCharger/medium_speed_charger',
    colors: [YELLOW_CHARGER, BLACK_CHARGER, BLUE_CHARGER],
  },
  {
    title: (
      <>
        WEV 급속 충전기
        <br />
        신속하게 언제 어디서든 간편 충전
      </>
    ),
    modelName: '급속충전기',
    capacities: [50, 100],
    descriptions: [
      <>
        PLC 모뎀 장착 및 <em>SOC제어로 전기차 배터리 과충전 방지</em>
      </>,
      <>자체 개발 충전커넥터&케이블 내장 자동 소화약제로 화재 대응 가능</>,
      <>
        <em>21.5인치 대형 디스플레이</em>로 사용자 시인성 및 편리성 향상
      </>,
      <>고효율 기자재 인증 완료</>,
      <>
        네트워크 원격 제어로 <em>실시간 업데이트 및 오류감지 모니터링</em> 가능
      </>,
      <>
        파워모듈 측면 배치로 간편한 <em>개폐 및 수리용이성 향상</em>
      </>,
    ],
    detailsLink: '/wev/product/50kW',
    imageSrc: '/images/main/renewal/chargers/fast_speed_charger',
    colors: [PURPLE_CHARGER],
  },
]

export const CHARGER_MODELS_EN = [
  {
    title: (
      <>
        Compact design with
        <br className="lg:hidden" />
        various functions for
        <br />
        personal electric vehicle charger
      </>
    ),
    modelName: 'Home Charger',
    capacities: [7],
    descriptions: [
      <>
        Personal <em>home charger</em>
      </>,
      <>
        <em>Customizable charging schedule</em>
      </>,
      <>
        Convenient use with <em>connector holder</em> integrated into the main unit
      </>,
      <>
        <em>Compact design with 5 color options and design patents</em>
      </>,
      <>
        <em>Status LED</em> to check the vehicle's charging status
      </>,
      <>
        <em>RFID system</em> for user authentication
      </>,
      <>
        Usable in <em>various environments</em> with <em>wall-mounted or stand-alone options</em>
      </>,
      <>
        <em>Adjustable charging speed (5kW/7kW)</em>
      </>,
    ],
    detailsLink: '/wev/product/non_public',
    imageSrc: '/images/main/renewal/chargers/privateSlowSpeedCharger/private_slow_speed_charger',
    colors: [WHITE_CHARGER, BLACK_CHARGER, BLUE_CHARGER, ORANGE_CHARGER, YELLOW_CHARGER, MINT_CHARGER],
  },
  {
    title: (
      <>
        Reliable performance with
        <br />
        stable charging and various lineup
      </>
    ),
    modelName: 'Normal Charger',
    capacities: [7, 11],
    descriptions: [
      <>
        <em>Fire prevention charger</em> (PLC modem installed) - Optional
      </>,
      <>
        <em>Compact design with 5 color options and design patents</em>
      </>,
      <>
        <em>7-inch touch display</em> for optimal UI
      </>,
      <>
        Real-time monitoring of <em>charger temperature</em> through control system and admin page
      </>,
      <>Improved ease of opening and repairability</>,
      <>
        <em>Automatic fire suppression</em> with internally developed charger connector & cable
      </>,
      <>
        Usable in <em>various environments</em> with <em>wall-mounted or stand-alone options</em>
      </>,
    ],
    detailsLink: '/wev/product/public_7kW',
    imageSrc: 'images/main/renewal/chargers/publicSlowSpeedCharger/public_slow_speed_charger',
    colors: [WHITE_CHARGER, BLACK_CHARGER, BLUE_CHARGER, ORANGE_CHARGER, YELLOW_CHARGER, MINT_CHARGER],
  },
  {
    title: (
      <>
        WEV signature model
        <br />
        Fast charging, various types, various spaces
      </>
    ),
    modelName: 'Medium Charger',
    capacities: [30],
    descriptions: [
      <>
        <em>PLC modem installed and SOC control to prevent overcharging</em>
      </>,
      <>Automatic fire suppression with internally developed charger connector & cable</>,
      <>
        <em>21.5-inch large display</em> for improved user visibility and convenience
      </>,
      <>High-efficiency equipment certification completed</>,
      <>
        <em>Real-time updates and error detection</em> through network remote control
      </>,
      <>
        Improved <em>ease of opening and repairability</em> with side-mounted power modules
      </>,
    ],
    detailsLink: '/wev/product/30kW',
    imageSrc: '/images/main/renewal/chargers/mediumSpeedCharger/medium_speed_charger',
    colors: [YELLOW_CHARGER, BLACK_CHARGER, BLUE_CHARGER],
  },
  {
    title: (
      <>
        WEV fast charger
        <br />
        Quick and easy charging
        <br className="lg:hidden" />
        anytime, anywhere
      </>
    ),
    modelName: 'Fast Charger',
    capacities: [50, 100],
    descriptions: [
      <>
        <em>PLC modem installed and SOC control to prevent overcharging</em>
      </>,
      <>Automatic fire suppression with internally developed charger connector & cable</>,
      <>
        <em>21.5-inch large display</em> for improved user visibility and convenience
      </>,
      <>High-efficiency equipment certification completed</>,
      <>
        <em>Real-time updates and error detection</em> through network remote control
      </>,
      <>
        Improved <em>ease of opening and repairability</em> with side-mounted power modules
      </>,
    ],
    detailsLink: '/wev/product/50kW',
    imageSrc: '/images/main/renewal/chargers/fast_speed_charger',
    colors: [PURPLE_CHARGER],
  },
]

export const CHARGER_MODELS_JP = [
  {
    title: (
      <>
        コンパクトなデザインで
        <br className="lg:hidden" />
        様々な機能を備えた
        <br />
        個人用電動車充電器
      </>
    ),
    modelName: '家庭用 充電器',
    capacities: [7],
    descriptions: [
      <>
        個人用の<em>家庭充電器</em>
      </>,
      <>
        <em>カスタマイズ可能な充電スケジュール</em>
      </>,
      <>
        <em>コネクタホルダー</em>が本体に統合されているため、便利に使用可能
      </>,
      <>
        <em>5色のオプションとデザイン特許</em>を持つ<em>コンパクトなデザイン</em>
      </>,
      <>
        車両の充電状態を確認できる<em>ステータスLED</em>
      </>,
      <>
        ユーザー認証のための<em>RFIDシステム</em>
      </>,
      <>
        <em>壁掛けまたはスタンドアロンのオプション</em>で<em>様々な環境</em>で使用可能
      </>,
      <>
        <em>充電速度調整可能（5kW/7kW）</em>
      </>,
    ],
    detailsLink: '/wev/product/non_public',
    imageSrc: '/images/main/renewal/chargers/privateSlowSpeedCharger/private_slow_speed_charger',
    colors: [WHITE_CHARGER, BLACK_CHARGER, BLUE_CHARGER, ORANGE_CHARGER, YELLOW_CHARGER, MINT_CHARGER],
  },
  {
    title: (
      <>
        安定した充電と多様なラインナップで
        <br />
        信頼性の高い性能
      </>
    ),
    modelName: 'レベル2充電器',
    capacities: [7, 11],
    descriptions: [
      <>
        <em>火災防止充電器</em>（PLCモデム搭載） - オプション
      </>,
      <>
        <em>5色のオプションとデザイン特許</em>を持つ<em>コンパクトなデザイン</em>
      </>,
      <>
        <em>7インチタッチディスプレイ</em>で最適なUI
      </>,
      <>
        制御システムと管理ページを通じた<em>充電器の温度</em>のリアルタイム監視
      </>,
      <>開閉性と修理性の向上</>,
      <>
        内部開発の充電コネクタとケーブルによる<em>自動消火</em>
      </>,
      <>
        <em>壁掛けまたはスタンドアロンのオプション</em>で<em>様々な環境</em>で使用可能
      </>,
    ],
    detailsLink: '/wev/product/public_7kW',
    imageSrc: 'images/main/renewal/chargers/publicSlowSpeedCharger/public_slow_speed_charger',
    colors: [WHITE_CHARGER, BLACK_CHARGER, BLUE_CHARGER, ORANGE_CHARGER, YELLOW_CHARGER, MINT_CHARGER],
  },
  {
    title: (
      <>
        WEVシグネチャーモデル
        <br />
        高速充電、さまざまな種類、さまざまなスペース
      </>
    ),
    modelName: '中速充電器',
    capacities: [30],
    descriptions: [
      <>
        <em>PLCモデム搭載とSOC制御による過充電防止</em>
      </>,
      <>内部開発の充電コネクタとケーブルによる自動消火</>,
      <>
        <em>21.5インチの大画面ディスプレイ</em>で視認性と利便性が向上
      </>,
      <>高効率機器認証完了</>,
      <>
        ネットワークリモートコントロールによる<em>リアルタイムの更新とエラー検出</em>
      </>,
      <>
        側面に取り付けられた電源モジュールによる<em>開閉性と修理性の向上</em>
      </>,
    ],
    detailsLink: '/wev/product/30kW',
    imageSrc: '/images/main/renewal/chargers/mediumSpeedCharger/medium_speed_charger',
    colors: [YELLOW_CHARGER, BLACK_CHARGER, BLUE_CHARGER],
  },
  {
    title: (
      <>
        WEVラピッドチャージャー
        <br />
        迅速で簡単な充電
        <br className="lg:hidden" />
        いつでもどこでも
      </>
    ),
    modelName: 'ラピッドチャージャー',
    capacities: [50, 100],
    descriptions: [
      <>
        <em>PLCモデム搭載とSOC制御による過充電防止</em>
      </>,
      <>内部開発の充電コネクタとケーブルによる自動消火</>,
      <>
        <em>21.5インチの大画面ディスプレイ</em>で視認性と利便性が向上
      </>,
      <>高効率機器認証完了</>,
      <>
        ネットワークリモートコントロールによる<em>リアルタイムの更新とエラー検出</em>
      </>,
      <>
        側面に取り付けられた電源モジュールによる<em>開閉性と修理性の向上</em>
      </>,
    ],
    detailsLink: '/wev/product/50kW',
    imageSrc: '/images/main/renewal/chargers/fast_speed_charger',
    colors: [PURPLE_CHARGER],
  },
]
