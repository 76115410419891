import { NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Main from '@src/features/main/components/Main'
import HeadContainer from '@src/components/HeadContainer'

export const getStaticProps = async ({ locale }: any) => ({
  props: {
    ...(await serverSideTranslations(locale, ['main', 'layout'])),
  },
})

const Home: NextPage = () => {
  return (
    <>
      <HeadContainer />
      <Main />
    </>
  )
}

export default Home
