export const SERVICE_INFO_KR = [
  {
    image: {
      src: '/images/main/renewal/service/building.png',
      alt: 'EL ELECTRIC Building',
      decoration: {
        src: '/images/main/renewal/service/globe.png',
        size: 200,
      },
    },
    info: {
      title: (
        <>
          국내 R&D센터에서 <em>자체 개발 및 제조</em>
        </>
      ),
      descriptions: [
        '충전기, 커넥터, 케이블 등의 핵심 부품을 국내에서 직접 설계하고 제조합니다',
        '생산과정에서 엄격한 품질 관리를 통해 제품의 성능 및 내구성을 최적화합니다',
        'WEV 회원들의 안전과 신뢰를 위해 안전한 충전 경험을 보장합니다',
      ],
    },
  },
  {
    image: {
      src: '/images/main/renewal/service/tech.png',
      alt: 'Tech',
      decoration: {
        src: '/images/main/renewal/service/stack.png',
        size: 264,
      },
    },
    info: {
      title: (
        <>
          전기차 충전 <em>서비스 운영(CPO)</em>
        </>
      ),
      descriptions: [
        '공시설, 사업장, 상업시설 등 다양한 장소에 충전소를 확보하고 설치합니다',
        '충전소의 운영과 관리를 담당하여 충전 서비스를 제공하고, 충전기의 유지 보수를 수행합니다',
        '충전 데이터 분석 및 부하 관리 등 다양한 서비스를 제공하여 고객들에게 편리한 충전 환경을 제공합니다',
      ],
    },
  },
  {
    image: {
      src: '/images/main/renewal/service/car_with_charger.png',
      alt: 'WEV Charger Next To A Charger',
      decoration: {
        src: '/images/main/renewal/service/vortex.png',
        size: 400,
      },
    },
    info: {
      title: (
        <>
          전기차 <em>SOC 기반 충전 관리</em>
        </>
      ),
      descriptions: [
        '충전기와 SOC 정보를 기반으로 차량의 배터리 충전 상태를 실시간으로 모니터링합니다',
        'SOC 정보를 활용하여 충전을 설정하고 관리하여 차량의 배터리를 효율적으로 충전합니다',
        '차량의 충전 이력을 분석하여 충전 패턴을 파악하고 최적화하여 사용자에게 최적의 충전 경험을 제공합니다',
      ],
    },
  },
]

export const SERVICE_INFO_EN = [
  {
    image: {
      src: '/images/main/renewal/service/building.png',
      alt: 'EL ELECTRIC Building',
      decoration: {
        src: '/images/main/renewal/service/globe.png',
        size: 200,
      },
    },
    info: {
      title: (
        <>
          <em>In-House Development and Manufacturing</em> at Domestic R&D Center
        </>
      ),
      descriptions: [
        'Design and manufacture key components such as chargers, connectors, and cables domestically.',
        'Optimize product performance and durability through strict quality control during the production process.',
        'Ensure a safe charging experience for WEV members to guarantee safety and reliability.',
      ],
    },
  },
  {
    image: {
      src: '/images/main/renewal/service/tech.png',
      alt: 'Tech',
      decoration: {
        src: '/images/main/renewal/service/stack.png',
        size: 264,
      },
    },
    info: {
      title: (
        <>
          "EV Charging <em>Service Operation (CPO)</em>"
        </>
      ),
      descriptions: [
        'Secure and install charging stations in various locations such as public facilities, workplaces, and commercial facilities.',
        'Operate and manage charging stations, provide charging services, and perform maintenance on chargers.',
        'Offer various services such as charging data analysis and load management to provide a convenient charging environment for customers.',
      ],
    },
  },
  {
    image: {
      src: '/images/main/renewal/service/car_with_charger.png',
      alt: 'WEV Charger Next To A Charger',
      decoration: {
        src: '/images/main/renewal/service/vortex.png',
        size: 400,
      },
    },
    info: {
      title: (
        <>
          EV <em>SOC-Based Charging Management</em>
        </>
      ),
      descriptions: [
        'Monitor the battery charging status of vehicles in real-time based on charger and SOC information.',
        'Set and manage charging using SOC information to charge vehicle batteries efficiently.',
        'Analyze charging history to understand and optimize charging patterns, providing the best charging experience for users.',
      ],
    },
  },
]

export const SERVICE_INFO_JP = [
  {
    image: {
      src: '/images/main/renewal/service/building.png',
      alt: 'EL ELECTRIC Building',
      decoration: {
        src: '/images/main/renewal/service/globe.png',
        size: 200,
      },
    },
    info: {
      title: (
        <>
          国内R&Dセンターで<em>自社開発および製造</em>
        </>
      ),
      descriptions: [
        '充電器、コネクター、ケーブルなどの主要部品を国内で直接設計・製造します',
        '生産過程で厳格な品質管理を行い、製品の性能および耐久性を最適化します',
        'WEV会員の安全と信頼のために、安全な充電体験を保証します',
      ],
    },
  },
  {
    image: {
      src: '/images/main/renewal/service/tech.png',
      alt: 'Tech',
      decoration: {
        src: '/images/main/renewal/service/stack.png',
        size: 264,
      },
    },
    info: {
      title: (
        <>
          電気自動車充電 <em>サービス運営(CPO)</em>
        </>
      ),
      descriptions: [
        '公共施設、事業所、商業施設など様々な場所に充電ステーションを確保して設置します',
        '充電ステーションの運営と管理を担当し、充電サービスを提供し、充電器の保守を行います',
        '充電データ分析や負荷管理など様々なサービスを提供し、顧客に便利な充電環境を提供します',
      ],
    },
  },
  {
    image: {
      src: '/images/main/renewal/service/car_with_charger.png',
      alt: 'WEV Charger Next To A Charger',
      decoration: {
        src: '/images/main/renewal/service/vortex.png',
        size: 400,
      },
    },
    info: {
      title: (
        <>
          電気自動車 <em>SOC基盤充電管理</em>
        </>
      ),
      descriptions: [
        '充電器とSOC情報に基づいて車両のバッテリー充電状態をリアルタイムでモニタリングします',
        'SOC情報を活用して充電を設定・管理し、車両のバッテリーを効率的に充電します',
        '車両の充電履歴を分析して充電パターンを把握し、最適化してユーザーに最適な充電体験を提供します',
      ],
    },
  },
]
