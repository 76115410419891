import Head from 'next/head'

export default function HeadContainer(props: any) {
  const { ...customMeta } = props

  const meta = {
    title: '위브이',
    desc: '위브이는 EV 충전 인프라 구축에 앞장서는 이엘일렉트릭의 전기차 충전 브랜드입니다.',
    url: 'https://wev-charger.com/',
    ...customMeta,
  }

  return (
    <>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />

        {/* 웹 탭 타이틀 */}
        <title>{`${meta.title} :: WEV`}</title>
        <meta content={meta.desc} name="description" />

        <meta
          name="keywords"
          content="위브이, WEV, wev, 전기차충전기, 전기차충전, EV충전기, 이엘일렉트릭, 위브이 충전기, 이엘일렉트릭 전기차충전기, 위브이어플, 전기차충전소, 전기차충전기 설치, 전기차충전기추천"
        />
        <meta name="author" content="위브이" />

        {/* Naver 블로그, 카카오톡 미리보기 설정 */}
        <meta property="og:title" content={meta.title} />
        <meta property="og:url" content={meta.url} />
        <meta property="og:description" content={meta.desc} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/images/OG_image.png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:locale" content="ko_KR" />
        <meta property="og:site_name" content="Wev Charger" />
        {/* <meta property="og:url" content={`https://dongsun-dev.vercel.app${router.asPath}`} /> */}
        {/* <link rel="canonical" href={`https://dongsun-dev.vercel.app${router.asPath}`} /> */}
        {/* <meta property="og:site_name" content="Wev" /> */}

        {/* 트위터 미리보기 설정 */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:url" content="https://wev-charger.com/" />
        <meta name="twitter:description" content={meta.desc} />
        <meta name="twitter:image" content="/images/OG_image.png" />
      </Head>
    </>
  )
}
