import React, { useEffect, useState } from 'react'
import { Container } from '@mui/material'
import Link from 'next/link'
import Logo from 'public/images/wev/wev_logo.png'
import { Arrow_Right_Alt } from '@src/assets/icons'
import { supportApis } from '@src/features/support/api'
import { INewsList } from '@src/features/support/types'
import { useRouter } from 'next/router'
import { nanoid } from '@reduxjs/toolkit'
import { useResponsive } from '@src/hooks/useResponsive'
import { useTranslation } from 'next-i18next'
import { convertUtcToZone } from '@src/utils/timeCalculator'
import { useAppSelector } from '@src/hooks/redux'
import Image from 'next/legacy/image'
import * as S from './News.styled'

const News = () => {
  const router = useRouter()
  const { t } = useTranslation('main')
  const { isMobile } = useResponsive(768)
  const [newsList, setNewsList] = useState<INewsList | null>(null)
  const newUserInfo = useAppSelector((state) => state.userInfo.userInfoMasking)

  const fetchData = async () => {
    const newFilter = {
      page: 1,
      query: '',
    }

    try {
      await supportApis.getNewsList(newFilter).then((res) => {
        if (res.data.resultCode === 'OK') {
          const { records } = res.data.resultData
          if (records === null || records.length === 0) {
            setNewsList(null)
            return
          }
          setNewsList(res.data.resultData)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [router])
  if (!newsList) {
    return null
  }
  return (
    <Container maxWidth="lg" sx={{ mb: isMobile ? '20px' : '120px' }} data-aos="fade-up">
      <S.Content>
        <h2>WEV {t('news.title')}</h2>
        <Link id="detailsLink" className="space-x-2" href={'/customer/news'}>
          <span>{t('news.뉴스 더보기')}</span>
          <Arrow_Right_Alt width={isMobile ? 18 : 32} />
        </Link>
        <S.ListWrapper>
          {isMobile ? (
            <>
              {newsList.records.slice(0, 4).map((el) => (
                <S.MobileListItem
                  key={nanoid()}
                  onClick={() => router.push(`/customer/news/${el.id}`)}
                  className="hover:cursor-pointer hover:bg-[#F9F8FF]"
                >
                  <div id="image">
                    {el.representativeImage ? (
                      <img src={el.representativeImage} />
                    ) : (
                      <div className="flex items-center justify-center w-full h-full text-[#ccc] text-[14px]">
                        이미지 정보 없음
                      </div>
                    )}
                  </div>
                  <div id="content">
                    <div id="title">
                      <p>{el.title}</p>
                    </div>

                    <div id="date">{convertUtcToZone(el.createdAt, newUserInfo.timeZone).split(' ')[0]}</div>
                  </div>
                </S.MobileListItem>
              ))}
            </>
          ) : (
            <>
              {newsList.records.slice(0, 8).map((el) => (
                <S.Item
                  key={nanoid()}
                  onClick={() => router.push(`/customer/news/${el.id}`)}
                  className="hover:cursor-pointer hover:bg-[#F9F8FF]"
                >
                  <div id="image">
                    {el.representativeImage ? (
                      <img src={el.representativeImage} />
                    ) : (
                      <S.DefaultImage>
                        <Image src={Logo} />
                      </S.DefaultImage>
                    )}
                  </div>
                  <div id="content">
                    <div id="title">
                      <p>{el.title}</p>
                    </div>
                    <p>{el.contentPreview}</p>
                  </div>
                  <div id="date">{convertUtcToZone(el.createdAt, newUserInfo.timeZone).split(' ')[0]}</div>
                </S.Item>
              ))}
            </>
          )}
        </S.ListWrapper>
      </S.Content>
    </Container>
  )
}

export default News
